import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import { MD } from '../../config/breakpoints';
import { Markdown } from '../../components/utils';

import Icons, { Icon } from './icons';

import * as styles from './blocks.module.scss';

//
// ITEM
//
export const Item = ({ image, header, text, icons }) => (
	<div className={styles.item}>
		<div className={styles.image}>
			<MediaQuery minWidth={MD}>
				{(matches) =>
					matches ? (
						<GatsbyImage
							className={styles.img}
							image={image.fixed.gatsbyImageData}
							alt=""
						/>
					) : (
						<GatsbyImage
							className={styles.img}
							image={image.fluid.gatsbyImageData}
							alt=""
						/>
					)
				}
			</MediaQuery>
		</div>
		<div className={styles.content}>
			<div className={styles.top}>
				<h2 className={styles.header}>{header}</h2>
				<Markdown content={text} />
			</div>
			<div>
				<div className={styles.icons}>
					<Icons items={icons} />
				</div>
			</div>
		</div>
	</div>
);

Item.defaultProps = {
	image: null,
	header: null,
	text: null,
	icons: null,
};

Item.propTypes = {
	image: PropTypes.object.isRequired,
	header: PropTypes.string.isRequired,
	text: PropTypes.string,
	icons: PropTypes.arrayOf(PropTypes.shape(Icon.propTypes)),
};

//
// WRAPPER
//
const Wrapper = ({ items }) =>
	items
		.filter(({ date }) => {
			const timestamp = new Date(date).getTime();
			if (isNaN(timestamp)) {
				return true;
			}

			return timestamp >= new Date().getTime();
		})
		.map(({ image, header, text, icons }, index) => (
			<Item key={index} image={image} header={header} text={text} icons={icons} />
		));

Wrapper.defaultProps = {
	items: [],
};

Wrapper.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)).isRequired,
};

export default Wrapper;
